// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="myclimate: Förderungsantrag richtig stellen"
        meta={[
          { name: 'description', content: 'Beim Ersatz Ihrer Öl- oder Gasheizung helfen wir Ihnen die Fördergelder über myclimate zu beantragen. Wie wir das machen, erfahren Sie in diesem Artikel.' },
          { name: 'keywords', content: 'myclimate, Fördergelder beantragen, Förderungsantrag' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='zrwfT006w8Cjarz9dENyW-5f22VOeljTH6Otx3Zo2KgP'
          id='myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5f22VOeljTH6Otx3Zo2KgP",
    "updatedAt": "2024-03-04T14:58:57.289Z",
    "title": " myclimate: So stellen Sie Ihren Förderungsantrag für einen Heizungsersatz",
    "anchorName": "myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz",
    "text": "<ul>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/\">myclimate: So stellen Sie Ihren Förderungsantrag für einen Heizungsersatz</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/#was-ist-myclimate\">Was ist myclimate?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/#myclimate-foerderprogramm-fuer-waermepumpen\">myclimate-Förderprogramm für Wärmepumpen</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/#wann-kommt-der-foerderungsbetrag-von-myclimate-zum-zug\">Wann kommt der Förderungsbetrag von myclimate zum Zug?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/#foerderkriterien-auf-einen-blick\">Förderkriterien auf einen Blick</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/#so-helfen-wir-ihnen-die-myclimate-leistungen-zu-beantragen\">So helfen wir Ihnen die myclimate-Leistungen zu beantragen.</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz/#wuenschen-sie-eine-beratung\">Wünschen Sie eine Beratung?</a></li>\n</ul>\n",
    "image": {
        "description": "Logo myclimate",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4NEbZFLGkeP4zJvAVtMhcv/31ab83512a61feb6e7c3bc33d991f767/2203---myclimate-Einleitung_und_Bild.png"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='zrwfT006w8Cjarz9dENyW-1x6bqtpxuF5E57UfVTtBlP'
          id='was-ist-myclimate'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "1x6bqtpxuF5E57UfVTtBlP",
    "updatedAt": "2022-03-10T08:52:50.006Z",
    "title": "Was ist myclimate?",
    "anchorName": "was-ist-myclimate",
    "text": "<p><a href=\"https://www.heizungsmacher.ch/foerdergelder/schweizweite-foerderung/\">myclimate</a> sieht sich global und lokal als Partner für wirksamen Klimaschutz. <strong>Gemeinsam mit Partnern aus der Wirtschaft sowie Privatpersonen will die Organisation die Zukunft der Welt nachhaltig gestalten</strong>.</p>\n<p>Über Partnerorganisationen ist <a href=\"https://www.myclimate.org/de/\">myclimate</a> in der <strong>Schweiz, sowie in weiteren Ländern wie Deutschland, Österreich, Schweden oder Norwegen vertreten</strong>. Gleichzeitig werden von Zürich aus Geschäfts- und Privatkunden weltweit betreut.</p>\n<p><strong>Mit Projekten höchster Qualität treibt myclimate weltweit messbaren Klimaschutz und eine nachhaltige Entwicklung voran</strong>. <strong>Die freiwillige Kompensation von <a href=\"https://www.heizungsmacher.ch/wissen/co2-gesetz/#was-besagt-das-co2-gesetz\">CO₂-Emissionen</a> erfolgt in derzeit mehr als 144 Klimaschutzprojekten in 41 Ländern</strong>. Emissionen werden reduziert, indem fossile Energiequellen durch erneuerbare Energien ersetzt, lokale Aufforstungsmassnahmen mit Kleinbauern umgesetzt und energieeffiziente Technologien implementiert werden. Myclimate-Klimaschutzprojekte erfüllen höchste Standards und leisten neben der Reduktion von Treibhausgasen nachweislich lokal und regional einen positiven Beitrag zur nachhaltigen Entwicklung.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='zrwfT006w8Cjarz9dENyW-2lxBSKogHQi5IhD05Gjy5f'
          id='myclimate-foerderprogramm-fuer-waermepumpen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "2lxBSKogHQi5IhD05Gjy5f",
    "updatedAt": "2022-03-29T18:55:12.892Z",
    "title": "myclimate-Förderprogramm für Wärmepumpen",
    "anchorName": "myclimate-foerderprogramm-fuer-waermepumpen",
    "text": "<p><strong><a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpen</a> gehören zu der umweltfreundlichsten Art, ein Zuhause zu heizen</strong>. Auch gerade deshalb gibt es ein spezielles <a href=\"https://www.heizungsmacher.ch/foerdergelder/schweizweite-foerderung/\">myclimate-Förderprogramm</a>, das den Ersatz von fossilen Energiequellen durch erneuerbare Energien betrifft. Dieses unterstützt Hauseigentümer:innen <strong>mit einem <a href=\"https://www.heizungsmacher.ch/foerdergelder/\">Förderbeitrag</a> im Bereich von 2'000 bis 7'000 Franken beim <a href=\"https://www.heizungsmacher.ch/heizungsersatz/\">Ersatz</a> ihrer alten <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Öl-</a> oder Erdgasheizung durch eine energieeﬃziente Wärmepumpe mit einer Leistung bis 15 kW</strong>. Die Höhe des Förderbetrages für die Wärmepumpe berechnet myclimate nach erfolgter Anmeldung anhand des bisherigen, jährlichen Energieverbrauches (18 Rp./kWh).</p>\n<p><strong>Der Förderbeitrag wird ausbezahlt, sobald die Wärmepumpe in Betrieb genommen wurde und myclimate das Anlagezertifikat Wärmepumpen-System-Modul erhalten hat</strong>. Der/die Hauseigentümer:in erhält das Zertifikat von der <a href=\"https://www.fws.ch/\">Fachvereinigung Wärmepumpen Schweiz</a>, sobald diese den Antrag geprüft hat. Dem Installateur und myclimate wird jeweils automatisch eine Kopie zugestellt.</p>\n<p><strong>Pro installierte Wärmepumpe werden in einem Einfamilienhaus jährlich etwa 2000 Liter Heizöl oder 2000 Kubikmeter Erdgas eingespart</strong>. Mit dieser Massnahme kann jeder Privathaushalt zur Reduktion von Treibhausgasemissionen beitragen.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='zrwfT006w8Cjarz9dENyW-5lmTY0CiIHkV6M2d4khhPv'
          id='wann-kommt-der-foerderungsbetrag-von-myclimate-zum-zug'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5lmTY0CiIHkV6M2d4khhPv",
    "updatedAt": "2022-03-10T08:55:23.719Z",
    "title": "Wann kommt der Förderungsbetrag von myclimate zum Zug?",
    "anchorName": "wann-kommt-der-foerderungsbetrag-von-myclimate-zum-zug",
    "text": "<p><strong>Gefördert werden <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpen</a> bis 15 kW thermische Heizleistung, die eine Heizöl- oder Erdgasheizung ersetzen und keine staatlichen Finanzhilfen beziehen können</strong>. Für grössere Leistungen von 15kW-400kW besteht das Förderprogramm Wärmepumpen für Mehrfamilienhäuser. Die Förderung gilt nur für den <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ersatz von Öl- oder Erdgasheizungen</a>, <strong>Elektroheizungen und andere Heizungsarten sind vom Programm ausgeschlossen</strong>. Zur Abklärung einer staatlichen Förderung an Ihrem Standort werden Sie durch die <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Experten der Heizungsmacher</a> beraten und unterstützt.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='zrwfT006w8Cjarz9dENyW-1YXPL3LOadM9yKzInv5ssM'
          id='foerderkriterien-auf-einen-blick'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "1YXPL3LOadM9yKzInv5ssM",
    "updatedAt": "2022-03-10T09:00:10.626Z",
    "title": "Förderkriterien auf einen Blick",
    "anchorName": "foerderkriterien-auf-einen-blick",
    "text": "<p>Damit Sie von der Förderung profitieren können, müssen bestimmte Kriterien erfüllt sein:</p>\n<ul>\n<li>Die Wärmepumpe <strong>ersetzt eine bestehende Heizöl- oder Erdgasheizung</strong>. (Elektro-Heizungen und andere Heizungsarten sind ausgeschlossen)</li>\n<li>Zum Zeitpunkt der Anmeldung <strong>besteht keine Möglichkeit, für die betroffene Wärmepumpe andere Fördergelder</strong> von Bund, Kanton oder Gemeinde zu erhalten.</li>\n<li><strong>Zum Zeitpunkt der Anmeldung wurde der Auftrag</strong> an den Installateur zur Planung und Installation der Wärmepumpe <strong>noch nicht erteilt</strong>.</li>\n<li>Planung, Installation und Inbetriebnahme werden <strong>gemäss dem Qualitätsstandard Wärmepumpen-System-Modul</strong> durchgeführt.</li>\n<li>Die Wärmepumpe wird als <strong>Hauptheizung</strong> eingesetzt.</li>\n<li>Die erforderliche thermische Heizleistung der Wärmepumpe beträgt <strong>maximal 15 kW</strong>.</li>\n<li>Die Wärmepumpe wird <strong>innerhalb der Schweiz</strong> installiert.</li>\n<li>Die durch die Wärmepumpe produzierte Wärme wird <strong>nicht über die Grundstücksgrenze</strong> exportiert.</li>\n<li>Die Wärmepumpe wird <strong>nicht in einem Betrieb mit einer CO2-Zielvereinbarung mit einer Energie-Agentur oder in einem am Schweizer Emissionshandel teilnehmenden Unternehmen installiert</strong>.</li>\n<li>Als Hauseigentümer:in übergeben Sie sämtliche <strong>Emissionsreduktionsrechte an myclimate</strong>.</li>\n<li>Als Hauseigentümer:in erklären Sie sich damit einverstanden, dass <strong>myclimate im Rahmen einer Stichprobenkontrolle die angegebenen Belege zum Brennstoffverbrauch überprüfen kann</strong>.</li>\n</ul>\n<p>Alle weiteren Kriterien finden Sie im <strong>Anmeldeformular</strong>, welches wir Ihnen <strong>schon im Voraus grösstenteils ausgefüllt</strong> zur Unterschrift zukommen lassen.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='zrwfT006w8Cjarz9dENyW-6Luo2DUQQOyyAY8kgS1WYe'
          id='so-helfen-wir-ihnen-die-myclimate-leistungen-zu-beantragen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "6Luo2DUQQOyyAY8kgS1WYe",
    "updatedAt": "2022-03-10T10:21:16.525Z",
    "title": "So helfen wir Ihnen die myclimate-Leistungen zu beantragen:",
    "anchorName": "so-helfen-wir-ihnen-die-myclimate-leistungen-zu-beantragen",
    "text": "<ul>\n<li>Das myclimate Anmeldeformular muss vor Baustart bei myclimate eingereicht werden. <strong>Wir füllen das Anmeldeformular grösstenteils für Sie aus und senden es Ihnen rechtzeitig vor dem Baustart zur Vervollständigung Ihrer Unterlagen zu</strong>. Das Anmeldeformular und die dazugehörigen Dokumente können Sie anschliessend mit dem von uns vorfrankierten Couverts an myclimate weiterleiten.</li>\n<li><strong>Nachdem Sie die provisorische Förderzusage von myclimate erhalten haben, wird der Auftrag vergeben</strong>.</li>\n<li>Mit Fertigstellung des Projektes, beziehungsweise <strong>nach Inbetriebnahme Ihrer Wärmepumpe, wird diese durch die Fachvereinigung Wärmepumpen Schweiz zertifiziert</strong>.</li>\n<li><strong>Das Anlagezertifikat</strong> wird durch die Fachvereinigung Wärmepumpen Schweiz <strong>automatisch an myclimate weitergeleitet um die Zahlung der Förderung bei myclimate freizugeben</strong>.</li>\n</ul>\n",
    "image": {
        "description": "das Bild zeigt eine Figur mit einem Formular oder einer Liste in der Hand",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/75SwXkZMiYegxsni5bvw8T/2875eefa2a78ccd868f35492f46b2ab7/2203---MYCLIMATE--Blogartikel-Einleitung-_-Bild.png"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='zrwfT006w8Cjarz9dENyW-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
